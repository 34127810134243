<!--
 * @Description: 品质管理-检查计划
 * @Author: 马俊
 * @Date: 2021-04-27 11:16:21
 * @LastEditors: 马俊
 * @LastEditTime:
 -->
<template>
  <div class="QualityInspectionTaskList-wrapper">
    <list
      ref="list"
      :exportPermission="'export'"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :exportMethod="exportMethod"
      :searchParams="searchParams"
      :isMultiSelect="true"
      :headers="headers"
      :selectable="selectable"
    >
      <template #searchSlot>
        <v-input label="计划名称" v-model="searchParams.name" />
        <v-select label="计划类型" v-model="searchParams.planType" :options="planTypeOps"/>
        <v-select label="计划层级" v-model="searchParams.scopeType" :options="scopeTypeOps"/>
        <v-select2 label="检查对象" v-model="searchParams.communityId" v-bind="communityParams" />
        <v-datepicker
          type="rangedatetimer"
          label="任务实际开始时间"
          format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.taskStartTimeS"
          :endTime.sync="searchParams.taskStartTimeE"
        />
        <v-datepicker
          type="rangedatetimer"
          label="任务实际结束时间"
          format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.taskEndTimeS"
          :endTime.sync="searchParams.taskEndTimeE"
        />
        <v-select label="任务状态" v-model="searchParams.status" :options="statusOps"/>
      </template>
      <template #operateSlot="scope">
        <!-- <v-button text="查看检查项" type="text" permission="view" @click="view(scope.row)" /> -->
        <v-button text="作废" v-if="scope.row.status === 0" type="text" permission="delete" @click="deleteItem(scope.row)" />
      </template>
      <template #batchSlot="scope">
        <v-button text="批量作废" permission="delete" @click="batchDelete(scope.selectedData)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import {
  getQualityCheckPlanTaskListURL,
  exportQualityCheckPlanTaskListURL,
  deleteQualityCheckPlanURL,
} from './api'
import {
  statusMap,
  statusOps,
  scopeTypeOps,
  scopeTypeMap,
  planTypeMap,
  planTypeOps,
} from './map'
import { communityParams } from 'common/select2Params'
import { createAlinkVNode } from '@/common/vdom'

export default {
  name: 'qualityInspectionTaskList',
  computed: {
    scopeTypeOps () {
			const userType = +this.$store.state.userInfo.userType
			let list = []
			if ([106, 100].includes(userType)) {
				list = scopeTypeOps()
			}else if ([102].includes(userType)) {
				list = scopeTypeOps().slice(1)
			}else if ([101].includes(userType)) {
				list = scopeTypeOps().slice(2)
			}
			list.unshift({
				text: '全部',
				value: undefined,
				label: '全部'
			})
			return list
		},
  },
  data () {
    return {
      searchUrl: getQualityCheckPlanTaskListURL,
      exportUrl: exportQualityCheckPlanTaskListURL,
      exportMethod: 'get',
      statusOps: statusOps(1),
      planTypeOps: planTypeOps(1),
      // scopeTypeOps: scopeTypeOps(1),
      communityParams,
      searchParams: {
        name: '',
        communityId: '',
        taskStartTimeS: '',
        taskStartTimeE: '',
        taskEndTimeS: '',
        taskEndTimeE: ''
      },
      headers: [
        {
          prop: '',
          label: '计划类型',
          formatter (row) {
            return planTypeMap[row.planType]
          }
        },
        {
          prop: 'taskName',
          label: '计划名称'
        },
        {
          prop: 'templateName',
          label: '检查表模板'
        },
        {
          prop: 'communityName',
          label: '检查对象'
        },
        {
          prop: 'planStartTime',
          label: '计划起止时间',
          formatter (row) {
            return `${row.planStartTime}~${row.planEndTime}`
          }
        },
        {
          prop: 'taskStartTime',
          label: '任务实际开始时间'
        },
        {
          prop: 'taskEndTime',
          label: '任务实际完成时间'
        },
        {
          prop: 'executeUserName',
          label: '检查负责人'
        },
        {
          prop: 'executeUserName',
          label: '检查人'
        },
        {
          prop: '',
          label: '计划层级',
          formatter (row) {
            return scopeTypeMap[row.scopeType]
          }
        },
        {
          prop: '',
          label: '任务进度',
          formatter: (row, prop) => {
            const { finishedItemCount = 0, totalItemCount = 0, status = 0 } = row;
            if (status == 4) {
              return ''
            }
            return createAlinkVNode(this, row, prop, {
              text: `${ finishedItemCount } / ${ totalItemCount }`,
              cb: this.edit
            })
          }
        },
        {
          prop: 'status',
          label: '任务状态',
          formatter (row) {
            return statusMap[row.status]
          }
        }
      ]
    }
  },
  watch: {
  },
  methods: {
    view (row) {
      this.$router.push({
        name: 'qualityInspectionView',
        query: {
          taskId: row.id
        }
      })
    },
    edit (row) {
      this.$router.push({
        name: 'qualityInspectionTaskForm',
        query: {
          taskId: row.id,
          isEdit: +row.status === 1 ? 1 : undefined
        }
      })
    },
    async deleteItem(row) {
      let isConfirm = await this.$confirm('删除操作后无法恢复数据，确定要删除吗？')
      if (isConfirm) {
        this.removeRequest([row.id])
      }
    },
    async batchDelete ({ data }) { // 批量删除
      const ids = data.map(v => v.id)
      if (ids.length === 0) {
        this.$message('请选择检查任务')
        return
      }
      let isConfirm = await this.$confirm('删除操作后无法恢复数据，确定要删除吗？')
      if (isConfirm) {
        this.removeRequest(ids)
      }
    },
    removeRequest (ids) { 
      this.$axios.post(deleteQualityCheckPlanURL, ids ).then(res => {
        if (res.status === 100) {
          this.$refs.list.searchData()
          this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    selectable (row, index) {
      return row.status === 0
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
