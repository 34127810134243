/** 检查表模板 */
// 查询计划报告列表
const getQualityCheckPlanTaskListURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckPlanTaskList`
// 查询计划报告列表
const exportQualityCheckPlanTaskListURL = `${API_CONFIG.butlerBaseURL}qualityManagement/exportQualityCheckPlanTaskList`
// 查询任务报告列表
const getQualityCheckPlanTaskItemListURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckPlanTaskItemList`
// 查询任务报告列表
const exportQualityCheckPlanTaskItemListURL = `${API_CONFIG.butlerBaseURL}qualityManagement/exportQualityCheckPlanTaskItemList`
// 批量删除检查计划
const deleteQualityCheckPlanURL = `${API_CONFIG.butlerBaseURL}qualityManagement/deleteQualityCheckPlan`

// 查询任务检查项详情
const queryURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckTaskReportDetail`

// 编辑任务检查项信息
const editURL = `${API_CONFIG.butlerBaseURL}qualityManagement/UpdateScQualityCheckPlanTaskDetail`   // type ->  1：保存  2：完成

export {
  getQualityCheckPlanTaskListURL,
  exportQualityCheckPlanTaskListURL,
  getQualityCheckPlanTaskItemListURL,
  exportQualityCheckPlanTaskItemListURL,
  deleteQualityCheckPlanURL,
  queryURL,
  editURL,
}
